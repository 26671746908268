import React from "react";

// components
import NaviBar from "../components/navbar/NaviBar";
import HeroVideo from "../components/hero/HeroVideo";
import About from "../components/about/About";
import Why from "../components/why-consider/whyConsider";
import TA from "../components/targeted-assets/TargetedAssets";
import FundOverview from "../components/fund-overview/FundOverview";
import Teams from "../components/team/Team";
import WhyDi from "../components/why-di/WhyDI";
// import AssetGallery from "../components/assetGallery/AssetGalleryContainer";
import DiBasics from "../components/di-basics/DiBasics";
import CloserLook from "../components/closer-look/CloserLook";
import InfoCenter from "../components/informationCenter/InformationCenterMUI";
import StartInvesting from "../components/startInvesting/StartInvesting";
import Footer from "../components/footer/Footer";
import RtiButton from "../components/RTI-button/RtiButton";

// 7/7/21 - Removing asset gallery, DiBasics from UI for rollout until we have assets.
// 7/28/21 - DiBasics ready and rolled out.

const Home = () => {
  return (
    <>
      <NaviBar />
      <HeroVideo />
      <About />
      <Why />
      <TA />
      <FundOverview />
      {/* <Teams /> */}
      <WhyDi />
      <DiBasics />
      <CloserLook />
      {/* <AssetGallery /> */}
      <InfoCenter />
      <StartInvesting />
      <Footer />
      <RtiButton />
    </>
  );
};

export default Home;

import React, { useContext, useState, useEffect } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { AuthContext } from "../../App";

// Images
import navLogo from "../../assets/images/SWIFII-240x50-COLOR.png";
import navLogoWhite from "../../assets/images/SWIFII-240x50-WHITE.png";

//  CSS
import "./NaviBar.css";

const NaviBar = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [transform, setTransform] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  useEffect(() => {
    // initiate the event handler
    window.addEventListener("scroll", handleScroll, true);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 350) {
      setTransform(true);
    } else {
      setTransform(false);
    }
  };

  const handleHomePress = () => {
    window.scrollTo(0, 0);
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -75;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  // renders the class of the dropdown menu based on scroll and if the dropdown is showing.
  function dropDownRender() {
    if (transform === true && showDropDown === true) {
      return "nav-dropdown-show-white";
    } else if (transform === false && showDropDown === true) {
      return "nav-dropdown-show-clear";
    } else {
      return "nav-dropdown-hide";
    }
  }

  // const logoutFunction = () => {
  //   dispatch({ type: "LOGOUT" });
  // };

  // Asset Gallery has been taken out temp until we get assets.
  return (
    <>
      <div
        className={
          transform === true
            ? "nav-main-wrapper-white"
            : "nav-main-wrapper-clear"
        }
      >
        <div className={transform === true ? "nav-container-white" : "nav-container-clear"}>
          {transform === true ? (
            <img
              src={navLogo}
              className="nav-logo"
              alt="sdcf logo"
              onClick={() => {
                handleHomePress();
                setShowDropDown(false);
              }}
            />
          ) : (
            <img
              src={navLogoWhite}
              className="nav-logo"
              alt="sdcf logo"
              onClick={() => {
                handleHomePress();
                setShowDropDown(false);
              }}
            />
          )}
          {/* dropdown nav for mobile */}
          <div className={dropDownRender()}>
            <ul className="mobile-ul">
              <li>
                <NavLink
                  smooth
                  to="#about"
                  activeClassName="selected"
                  className={transform === true ? "d-blue" : "white"}
                  onClick={() => {
                    setShowDropDown(false);
                  }}
                >
                  ABOUT
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  smooth
                  to="/#team"
                  activeClassName="selected"
                  className={transform === true ? "d-blue" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    setShowDropDown(false);
                  }}
                >
                  TEAM
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  smooth
                  to="/#why-digital-infrastructure"
                  activeClassName="selected"
                  className={transform === true ? "d-blue" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    setShowDropDown(false);
                  }}
                >
                  WHY DIGITAL INFRASTRUCTURE
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  smooth
                  to="/#asset-gallery"
                  activeClassName="selected"
                  className={transform === true ? "d-blue" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    setShowDropDown(false);
                  }}
                >
                  ASSET GALLERY
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  smooth
                  to="/#information-center"
                  activeClassName="selected"
                  className={transform === true ? "d-blue" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    setShowDropDown(false);
                  }}
                >
                  INFORMATION CENTER
                </NavLink>
              </li>
              <li onClick={() => dispatch({ type: "LOGOUT" })}>
                <a
                  href=""
                  className={transform === true ? "d-blue uc" : "white uc"}
                >
                  Log out
                </a>
              </li>
            </ul>
          </div>
          <div
            className="navbar-mobile-hamburger"
            onClick={() => setShowDropDown(!showDropDown)}
          >
            {/* <span>M</span> */}
            <div
              className={
                transform === true
                  ? "navbar-navline-gray"
                  : "navbar-navline-white"
              }
            ></div>
            <div
              className={
                transform === true
                  ? "navbar-navline-gray"
                  : "navbar-navline-white"
              }
            ></div>
            <div
              className={
                transform === true
                  ? "navbar-navline-gray"
                  : "navbar-navline-white"
              }
            ></div>
          </div>
          <div className="nav-links-container">
            <ul className="nav-link-ul">
              <li>
                <NavLink
                  smooth
                  to="/#about"
                  activeClassName="selected"
                  className={transform === true ? "d-blue" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  ABOUT
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  smooth
                  to="/#team"
                  activeClassName="selected"
                  className={transform === true ? "d-blue" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  TEAM
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  smooth
                  to="/#why-digital-infrastructure"
                  activeClassName="selected"
                  className={transform === true ? "d-blue" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  WHY DIGITAL INFRASTRUCTURE
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  smooth
                  to="/#asset-gallery"
                  activeClassName="selected"
                  className={transform === true ? "d-blue" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  ASSET GALLERY
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  smooth
                  to="/#information-center"
                  activeClassName="selected"
                  className={transform === true ? "d-blue" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  INFORMATION CENTER
                </NavLink>
              </li>
              <li onClick={() => dispatch({ type: "LOGOUT" })}>
                <a
                  href=""
                  className={transform === true ? "d-blue uc" : "white uc"}
                >
                  Log out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default NaviBar;
